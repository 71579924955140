<template>
  <div class="confirm-subscription">
    <div>
      <h1 class="font-20 black--text d-block font-700">
        Reconsider Your Decision?
      </h1>
      <p class="font-16 font-400 icon-black--text mb-6">
        We're sorry to see you go. If there's an issue we can help with, please
        let us know.
      </p>
    </div>

    <div class="cancel-notice d-flex gap-5 my-8">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="31"
        viewBox="0 0 31 31"
        fill="none"
      >
        <g clip-path="url(#clip0_4357_390)">
          <path
            d="M31 15.5086C30.9928 24.0512 24.0155 31.0163 15.4829 31C6.93761 30.9837 -0.0207275 24.005 4.6394e-05 15.4742C0.0208202 6.93977 6.97283 0 15.5009 0C24.0435 0.00090503 31.0073 6.96873 31 15.5086Z"
            fill="#FDD039"
          />
          <path
            d="M31 15.5086C30.9928 24.0512 24.0155 31.0163 15.4829 31C6.93761 30.9837 -0.0207275 24.005 4.6394e-05 15.4742C0.0208202 6.93977 6.97283 0 15.5009 0C24.0435 0.00090503 31.0073 6.96873 31 15.5086Z"
            fill="black"
          />
          <path
            d="M15.4761 19.5809C14.9794 19.5809 14.4826 19.5728 13.9868 19.5836C13.6896 19.5909 13.5568 19.4651 13.5361 19.17C13.4024 17.2586 13.2651 15.3481 13.1224 13.4376C12.9761 11.4827 12.8334 9.52693 12.6681 7.57297C12.5434 6.09867 13.415 4.80267 14.8042 4.45242C16.1825 4.10398 17.5698 4.80086 18.1253 6.12673C18.3113 6.5711 18.3682 7.039 18.3321 7.51866C18.1515 9.95772 17.9717 12.3959 17.7893 14.8349C17.6818 16.273 17.5662 17.7111 17.465 19.1501C17.4433 19.4605 17.3133 19.5927 16.999 19.5845C16.4914 19.571 15.9838 19.5809 15.4752 19.5809H15.4761Z"
            fill="#FDD039"
          />
          <path
            d="M17.8205 24.3215C17.8169 25.622 16.7674 26.6737 15.4812 26.6646C14.2032 26.6556 13.1338 25.5732 13.1446 24.2989C13.1564 23.0146 14.2203 21.9802 15.5219 21.9883C16.818 21.9974 17.8251 23.0183 17.8205 24.3215Z"
            fill="#FDD039"
          />
        </g>
        <defs>
          <clipPath id="clip0_4357_390">
            <rect width="31" height="31" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <p>
        Your plan remains active until
        {{ new Date(profile.planDetails.endDate).toLocaleDateString() }}. For
        more details, please review our
        <a href="https://ofero.co/terms/" target="_blank"
          >Terms and Conditions</a
        >.
      </p>
    </div>

    <p class="mb-0 font-16 font-400">
      If you decide to proceed with the cancellation, please click the button
      below.
    </p>

    <div class="d-flex align-center justify-end mt-3">
      <v-btn
        class="radius-15 flex-grow-1"
        height="45px"
        color="danger"
        elevation="0"
        @click="cancelSubscription()"
        :loading="loading"
        :disabled="loading"
      >
        <span class="white--text font-18 font-600">Confirm cancellation</span>
      </v-btn>

      <v-btn
        class="ml-4 no-box-shadow"
        width="80px"
        height="45px"
        color="transparent"
        @click="$emit('close')"
      >
        <span class="font-18 font-400">Cancel</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      loading: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    cancelSubscription() {
      this.loading = true;

      const isTrialing = this.profile.planDetails.status === "trialing";

      if (isTrialing) {
        this.$axios
          .post(`${process.env.VUE_APP_SUBSCRIPTIONS}/trial-unset`)
          .then((res) => {
            console.log(res, "cancelled");
            this.$store.dispatch("showSnack", {
              text: "Your trial is cancelled successfully",
              color: "success",
              timeout: 3000,
            });
            location.reload();
            this.loading = false;
          })
          .catch((err) => {
            console.error({ err });
            this.$store.dispatch("showSnack", {
              text: err.message || err.error || "Something went wrong!",
              color: "error",
              timeout: 3000,
            });
            this.loading = false;
          });
      } else {
        this.$axios
          .post(process.env.VUE_APP_PAYMENT_GATEWAY)
          .then((res) => {
            window.open(res.data.sessionUrl, "_blank");
            this.loading = false;
          })
          .catch((err) => {
            console.error({ err });
            this.$store.dispatch("showSnack", {
              text: err.message || err.error || "Something went wrong!",
              color: "error",
              timeout: 3000,
            });
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-notice {
  border-radius: 19px;
  background: #fdd039;
  padding: 15px;
}
</style>
