<template>
  <div class="upgrade-page pa-5 pa-lg-0" v-if="profile">
    <div class="mb-6">
      <span class="font-32 d-block"
        >Plan Details</span
      >
    </div>
    <v-row>
      <v-col cols="12" sm="6" md="4" xl="3">
        <div class="h-100">
          <current-plan
            :item="plans.find((item) => item._id == currentPlan._id)"
            @show-plans="showPlans = true"
            @cancel-subscription="cancelSubscriptionDialog = true"
          />
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        xl="3"
        v-for="(plan, i) in plans.filter((plan) => !plan.isFree)"
        :key="i"
      >
        <div class="h-100" v-if="profile.plan.isFree || showPlans">
          <view-plan
            :item="plan"
            @update="getPlans"
            @subscribe="showConfirmSubscriptionDialog"
          ></view-plan>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmDialog"
      max-width="610px"
      content-class="white pa-8"
    >
      <confirm-subscription
        v-if="this.profile.plan.isFree"
        :selected-plan="selectedPlan"
        @update="updateProfile()"
        @close="confirmDialog = false"
      />

      <confirm-update-plan
        v-else
        :selected-plan="selectedPlan"
        @update="updateProfile()"
        @close="confirmDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-model="cancelSubscriptionDialog"
      max-width="590px"
      content-class="white pa-10"
    >
      <cancel-subscription @close="cancelSubscriptionDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import CurrentPlan from "../../components/dialogs/CurrentPlan.vue";
import ViewPlan from "../../components/dialogs/ViewPlan.vue";
import PaymentForm from "./components/PaymentForm.vue";
import ConfirmSubscription from "./components/ConfirmSubscription.vue";
import ConfirmUpdatePlan from "./components/ConfirmUpdatePlan.vue";
import CancelSubscription from "./components/CancelSubscription.vue";

export default {
  components: {
    ViewPlan,
    CurrentPlan,
    ConfirmSubscription,
    ConfirmUpdatePlan,
    CancelSubscription,
  },
  data() {
    return {
      plans: [],
      confirmDialog: false,
      cancelSubscriptionDialog: false,
      selectedPlan: {},
      showPlans: false,
    };
  },
  computed: {
    currentPlan() {
      return { ...this.profile.plan, ...this.profile.planDetails };
    },
  },
  methods: {
    async getPlans() {
      let { data } = await this.$axios.get(process.env.VUE_APP_PUBLIC_PLANS);
      if (data.success) {
        this.plans = data.plans;

        console.log(
          data.plans.filter((plan) => {
            console.log(
              plan._id === this.currentPlan._id,
              this.currentPlan.isFree,
              !(plan._id === this.currentPlan._id && this.currentPlan.isFree)
            );
            return !(
              plan._id === this.currentPlan._id && this.currentPlan.isFree
            );
          })
        );

        this.$store.dispatch("setProfile");
      }
    },
    showConfirmSubscriptionDialog(selectedPlan) {
      this.selectedPlan = selectedPlan;
      this.$nextTick(() => {
        this.confirmDialog = true;
      });
    },
    updateProfile() {
      this.confirmDialog = false;
      // this.$store.dispatch("setProfile");
      location.reload();
    },
  },
  async mounted() {
    await this.getPlans();
    if (Object.keys(this.profile).length) {
      // console.log({ p: this.profile });
      // this.currentPlan = {
      //   ...this.profile,
      // };
      // this.currentPlan.package =
      //   this.profile.planDetails.planType == 0
      //     ? this.profile.plan.package.pricePerMonth
      //     : this.profile.plan.package.pricePerYear;
      // this.currentPlan.planDetails = {
      //   ...this.profile.plan,
      //   ...this.profile.planDetails,
      // };
      // this.currentPlan.pricingInfo = this.profile.pricingInfo;
    }
  },
};
</script>

<style lang="scss">
.upgrade-page {
  padding: 30px 0px;

  @media (max-width: 700px) {
    padding: 10px 0px;
  }
}
</style>
