<template>
  <div class="selected-plan view-plan h-100">
    <div>
      <div
        class="view-plan__head d-flex flex-column align-center justify-center"
      >
        <div class="text-center">
          <span class="font-600 font-24">{{ item.title }}</span>
        </div>
      </div>
      <div class="mt-6">
        <h1 class="font-600 font-18 text-center">Items</h1>
        <v-tabs v-model="numberOfProductsKey" centered>
          <v-tab
            v-for="numberOfProducts in item.package.numberOfProducts"
            :key="numberOfProducts._id"
            @click="selectNumberOfProducts(numberOfProducts)"
            ><span class="font-18 font-600" v-if="numberOfProducts.number">{{
              numberOfProducts.number
            }}</span></v-tab
          >
        </v-tabs>
      </div>
      <div class="mt-6">
        <h1 class="font-600 font-18 text-center">Screens</h1>
        <v-tabs v-model="numberOfPreviewsKey" centered>
          <v-tab
            v-for="numberOfPreviews in item.package.numberOfPreviews"
            :key="numberOfPreviews._id"
            @click="selectNumberOfPreviews(numberOfPreviews)"
            ><span class="font-18 font-600" v-if="numberOfPreviews.number">{{
              numberOfPreviews.number
            }}</span></v-tab
          >
        </v-tabs>
      </div>

      <!-- monthly / yearly -->
      <div class="date-tabs mt-6">
        <v-tabs v-model="dateType" centered>
          <v-tab @click="viewDateHandler('monthly')"
            ><span class="font-16"> Monthly</span></v-tab
          >
          <v-tab @click="viewDateHandler('yearly')"
            ><span class="font-16"> Yearly</span></v-tab
          >
        </v-tabs>
      </div>

      <div
        class="view-plan__main-price mt-6 d-flex align-center justify-center"
      >
        <div v-if="!item.isFree">
          <span class="font-62">{{ price }}</span>
          <span class="font-45">$</span>
        </div>
        <div v-else>
          <span class="font-62">FREE</span>
        </div>
      </div>
    </div>

    <!-- features -->
    <div v-if="item.features" class="my-4 mx-5 flex-grow-1">
      <h1 class="font-800 font-18">Features</h1>
      <div
        class="d-flex align-center mb-1"
        v-for="(feature, i) in item.features"
        :key="i"
      >
        <div
          class="view-plan__check mr-2 primary d-flex justify-center align-center"
        >
          <img :src="require('@/assets/images/icons/check.svg')" alt="" />
        </div>
        <span class="font-16 font-600">{{ feature }}</span>
      </div>
    </div>
    <div v-if="!noAction" class="px-4 pb-5">
      <v-btn
        class="mt-6 radius-15"
        height="45px"
        width="100%"
        color="primary"
        elevation="0"
        block
        @click="$emit('subscribe', selectedPlan)"
        :loading="loading"
      >
        <span class="white--text font-22 font-300">{{
          item.isFree ? "Subscribe" : "Upgrade"
        }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PlanTypes from "../../helpers/planTypesEnum.js";

export default {
  props: {
    item: {
      type: Object,
      default: Object,
    },
    noAction: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    numberOfProductsId: "",
    numberOfPreviewsId: "",
    numberOfProductsKey: 0,
    numberOfPreviewsKey: 0,
    type: "pricePerMonth",
    selectedCount: "",
    dateType: 0,
    loading: false,
    PlanTypes,
    confirmDialog: false,
  }),
  computed: {
    ...mapState(["profile"]),
    price() {
      const selectedNumberOfProducts = this.item.pricing.products.find(
        (product) => product.id === this.numberOfProductsId
      );

      const selectedNumberOfPreviews = this.item.pricing.previews.find(
        (preview) => preview.id === this.numberOfPreviewsId
      );

      return (
        Number(
          selectedNumberOfProducts
            ? selectedNumberOfProducts[this.type]
            : this.item.pricing.products[0][this.type]
        ) +
          Number(
            selectedNumberOfPreviews
              ? selectedNumberOfPreviews[this.type]
              : this.item.pricing.previews[0][this.type]
          ) || 0
      );
    },
    selectedPlan() {
      return {
        ...this.item,
        price: this.price,
        plan: this.item._id,
        numberOfPreviewsId: this.numberOfPreviewsId,
        numberOfProductsId: this.numberOfProductsId,
        isRenew: false,
        planType:
          this.type == "pricePerMonth"
            ? this.PlanTypes.MONTHLY
            : this.PlanTypes.YEARLY,
      };
    },
  },
  methods: {
    viewDateHandler(date) {
      if (date == "yearly") {
        this.type = "pricePerYear";
      } else {
        this.type = "pricePerMonth";
      }
    },
    selectNumberOfProducts(numberOfProducts) {
      this.numberOfProductsId = numberOfProducts._id;
    },
    selectNumberOfPreviews(numberOfPreviews) {
      this.numberOfPreviewsId = numberOfPreviews._id;
    },
    async subscribe() {
      if (!localStorage.getItem("token")) {
        this.$router.replace("/signin");
        return;
      }

      this.loading = true;

      let planSelected;
      planSelected = {
        plan: this.item._id,
        numberOfPreviewsId: this.numberOfPreviewsId,
        numberOfProductsId: this.numberOfProductsId,
        isRenew: false,
        planType:
          this.type == "pricePerMonth"
            ? this.PlanTypes.MONTHLY
            : this.PlanTypes.YEARLY,
      };

      // if (this.type == "pricePerYear") {
      //   delete planSelected.stripeMonthlyplanId;
      //   delete planSelected.pricePerMonth;
      // } else {
      //   delete planSelected.stripeYearlyplanId;
      //   delete planSelected.pricePerYear;
      // }

      console.log(planSelected);
      // start trial
      this.$axios
        .post(process.env.VUE_APP_SUBSCRIPTIONS, planSelected)
        .then((res) => {
          console.log(res, "res plan");
          this.loading = false;
          this.$store.dispatch("showSnack", {
            text: "Now , you can start your trial",
            color: "success",
            timeout: 3000,
          });

          this.$store.dispatch("setPlanSelected", planSelected);
          this.$emit("update");
          // setTimeout(() => this.$router.push("/payment"), 1000);
        })
        .catch((err) => {
          this.loading = false;
          this.$store.dispatch("showSnack", {
            text: err.message,
            color: "error",
            timeout: 3000,
          });
        });
    },
  },
  mounted() {
    // this.price = this.item.package;
    this.numberOfProductsKey = this.item.package.numberOfProducts.length;
    this.numberOfPreviewsKey = this.item.package.numberOfPreviews.length;
    this.numberOfProductsId = this.item.package.numberOfProducts[0]._id;
    this.numberOfPreviewsId = this.item.package.numberOfPreviews[0]._id;
    // this.selectedCount = this.item.package.numberOfProducts;
    // this.selectedPlan = this.item.package._id;
    console.log(
      this.item.package.numberOfProducts[0]._id,
      this.item.package.numberOfPreviews[0]._id
    );
  },
};
</script>

<style lang="scss">
.view-plan {
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  display: flex;

  &.selected-plan {
    .view-plan__head {
      background: #0195a9;
      color: #ffffff;
    }
  }

  &__head {
    height: 100px;
    width: 100%;
    background: #e3e3e3;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
  }

  &__main-price {
    background: #ececec;
    height: 120px;
  }

  &__check {
    border-radius: 9px;
    width: 23px;
    height: 23px;

    img {
      width: 20px;
      height: 15px;
    }
  }

  .v-tabs-slider-wrapper {
    height: 100% !important;
    border-radius: 30px !important;
    overflow: hidden !important;

    .v-tab-slider {
      height: 100% !important;
    }
  }

  .v-tab {
    z-index: 99 !important;
    font-weight: 600px !important;
    font-size: 20px !important;
    color: black !important;
    background: transparent !important;
    min-width: auto;
    border-radius: 30px;

    &:hover:before {
      border-radius: 30px;
    }

    &:active {
      // background: $primary !important;
      // color: #fff !important;
    }
  }

  .date-tabs {
    .v-tab {
      font-size: 16px !important;
    }
  }

  .v-tab--active {
    color: white !important;
    // background: $primary;
  }

  .v-tabs {
    width: auto;
    margin: auto;
    border-radius: 63px;
    overflow: hidden;

    .v-slide-group__content {
      // background: #d9d9d9;
    }
  }
}
</style>
