<template>
  <div
    class="view-plan h-100"
    v-if="Object.keys(item).length && Object.keys(profile).length"
  >
    <div>
      <div
        class="view-plan__head d-flex flex-column align-center justify-center"
      >
        <div class="text-center">
          <span class="font-600 font-30">{{ item.title }}</span>
        </div>
        <p v-if="!publicPage" class="font-18 mb-0">Current Plan</p>
      </div>

      <div class="mt-6 mx-5">
        <ul class="px-0">
          <li class="font-600 font-16 mb-2">
            Items:
            {{
              profile.planDetails
                ? profile.planDetails.productsAllowed
                : item.pricing.products[0].itemsAllowed
            }}
          </li>
          <li class="font-600 font-16 mb-2">
            Screens:
            {{
              profile.planDetails
                ? profile.planDetails.previewsAllowed
                : item.pricing.previews[0].screensAllowed
            }}
          </li>
          <li class="font-600 font-16 mb-2">
            Status: {{ item.active ? "Active" : "Inactive" }}
          </li>
          <li class="font-600 font-16 mb-2">Price: {{ price }}</li>
          <li class="font-600 font-16 mb-2" v-if="profile.planDetails">
            Started:
            {{
              moment(profile.planDetails.subscriptionDate).format("DD MMM YYYY")
            }}
          </li>
          <li class="font-600 font-16 mb-2" v-if="profile.planDetails">
            Expires:
            {{
              profile.plan.isFree
                ? "Free forever"
                : moment(profile.planDetails.endDate).format("DD MMM YYYY")
            }}
          </li>
        </ul>
      </div>
    </div>

    <!-- features -->
    <div v-if="item.features" class="mt-4 mx-5 flex-grow-1">
      <h1 class="font-800 font-18">Features</h1>
      <div
        class="d-flex align-center mb-1"
        v-for="(feature, i) in item.features"
        :key="i"
      >
        <div
          class="view-plan__check mr-2 primary d-flex justify-center align-center"
        >
          <img :src="require('@/assets/images/icons/check.svg')" alt="" />
        </div>
        <span class="font-16 font-600">{{ feature }}</span>
      </div>
    </div>
    <div v-if="$router.history.current.name === 'Plans'" class="px-4 pb-5">
      <v-btn
        class="mt-6 radius-15"
        height="45px"
        width="100%"
        color="primary"
        elevation="0"
        block
        @click="$router.push('/signup')"
        :loading="loading"
      >
        <span class="white--text font-22 font-300">Start for free</span>
      </v-btn>
    </div>

    <div v-else-if="!profile.plan.isFree" class="px-4 pb-5">
      <v-btn
        class="mt-6 radius-15"
        height="45px"
        width="100%"
        color="primary"
        elevation="0"
        block
        @click="$emit('show-plans')"
        :loading="loading"
      >
        <span class="white--text font-22 font-300">Plan options</span>
      </v-btn>

      <v-btn
        class="mt-2 radius-15"
        height="45px"
        width="100%"
        color="danger"
        elevation="0"
        block
        @click="$emit('cancel-subscription')"
      >
        <span class="white--text font-22 font-300">Cancel Subscription</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import planTypesEnum from "../../helpers/planTypesEnum";

export default {
  props: {
    item: {
      type: Object,
      default: Object,
    },
    publicPage: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    numberOfProductsId: "",
    numberOfPreviewsId: "",
    countValue: "",
    type: "pricePerMonth",
    selectedCount: "",
    selectedPlan: "",
    dateType: 0,
    loading: false,
  }),
  computed: {
    ...mapState(["profile"]),
    price() {
      // const selectedNumberOfProducts =
      //   this.profile.pricing.productsPricePerMonth;

      // const selectedNumberOfPreviews =
      //   this.profile.pricing.previewsPricePerMonth.find(
      //     (preview) => preview.id === this.numberOfPreviewsId
      //   );

      // console.log(selectedNumberOfProducts, selectedNumberOfPreviews);
      if (this.profile.pricing) {
        return (
          Number(this.profile.pricing.productsPricePerMonth || 0) +
          Number(this.profile.pricing.previewsPricePerMonth || 0)
        );
      } else {
        return "FREE";
      }
    },
  },
  methods: {
    moment,
    viewDateHandler(date) {
      if (date == "yearly") {
        this.type = "pricePerYear";
      } else {
        this.type = "pricePerMonth";
      }
    },
    selectNumberOfProducts(numberOfProducts, i) {
      this.numberOfProductsId = numberOfProducts._id;
    },
    selectNumberOfPreviews(numberOfPreviews, i) {
      this.numberOfPreviewsId = numberOfPreviews._id;
    },
    async updatePlan() {
      // if (!localStorage.getItem("token")) {
      //   this.$router.replace("/signin");
      //   return;
      // } else {
      //   let planSelected;
      //   if (!this.profile.isFirstPlan) {
      //     planSelected = {
      //       ...this.price,
      //       pricingId: this.price._id,
      //       planId: this.item._id,
      //       title: this.item.title,
      //       numberOfProducts: this.item.package.numberOfProducts.find(
      //         (el) => el._id == this.price.numberOfProductsId
      //       ).number,
      //     };
      //     if (this.type == "pricePerYear") {
      //       delete planSelected.stripeMonthlyplanId;
      //       delete planSelected.pricePerMonth;
      //     } else {
      //       delete planSelected.stripeYearlyplanId;
      //       delete planSelected.pricePerYear;
      //     }
      //     //console.log(planSelected);
      //   } else {
      //     this.loading = true;
      //     // start trial
      //     this.$axios
      //       .patch(`${process.env.VUE_APP_PLANS}/${this.item._id}`)
      //       .then(() => {
      //         this.$store.dispatch("showSnack", {
      //           text: "Now , you can start your trial",
      //           color: "success",
      //           timeout: 3000,
      //         });
      //         this.loading = false;
      //       })
      //       .catch((err) => {
      //         //console.log(err);
      //         this.loading = false;
      //         this.$store.dispatch("showSnack", {
      //           text: err.message,
      //           color: "error",
      //           timeout: 3000,
      //         });
      //       });
      //     return;
      //   }
      //   //console.log("item ", this.item);
      //   //console.log(planSelected);
      //   this.$store.dispatch("setPlanSelected", planSelected);
      //   this.$router.push("/payment");
      // }
    },
  },
  created() {
    console.log(this.$router.history.current.name);
  },
};
</script>

<style lang="scss">
.view-plan {
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  display: flex;

  &.selected-plan {
    .view-plan__head {
      background: #0195a9;
      color: #ffffff;
    }
  }

  &__head {
    height: 100px;
    width: 100%;
    background: #e3e3e3;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
  }

  &__main-price {
    background: #ececec;
    height: 120px;
  }

  // &__check {
  //   border-top-right-radius: 25px;
  //   border-bottom-right-radius: 25px;
  // }

  ul {
    list-style: none;
  }

  .v-tabs-slider-wrapper {
    height: 100% !important;
    border-radius: 30px !important;
    overflow: hidden !important;

    .v-tab-slider {
      height: 100% !important;
    }
  }

  .v-tab {
    z-index: 99 !important;
    font-weight: 600px !important;
    font-size: 20px !important;
    color: black !important;
    background: #d9d9d9;
    min-width: auto;
  }

  .date-tabs {
    .v-tab {
      font-size: 16px !important;
    }
  }

  .v-tab--active {
    color: white !important;
    background: $primary;
  }

  .v-tabs {
    width: auto;
    margin: auto;
    border-radius: 63px;
    overflow: hidden;
  }
}
</style>
