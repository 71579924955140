<template>
  <div class="confirm-subscription">
    <div>
      <h1 class="font-20 black--text d-block font-700">
        Confirm Your Subscription
      </h1>
      <p class="font-18 icon-black--text mb-6">
        Upgrade to
        <span class="font-700">{{ selectedPlan.package.type }}</span> and enjoy
        these benefits
      </p>
    </div>

    <div class="d-flex">
      <div>
        <h2 class="font-800 font-18">Plan Details</h2>
        <div>
          Items:
          <span class="font-600">{{ numberOfProducts.number || "-" }}</span>
        </div>
        <div>
          Screen:
          <span class="font-600">{{ numberOfPreviews.number || "-" }}</span>
        </div>
        <div>
          Price:
          <span class="font-600">{{
            selectedPlan.price ? selectedPlan.price + "$" : "FREE"
          }}</span>
        </div>
        <div>
          Started:
          <span class="font-600">{{ startDate }}</span>
        </div>
        <div>
          Expires: <span class="font-600">{{ endDate }}</span>
        </div>
      </div>

      <!-- features -->
      <div v-if="selectedPlan.features" class="ml-10">
        <h2 class="font-800 font-18">Features</h2>
        <div
          class="d-flex align-center mb-1"
          v-for="(feature, i) in selectedPlan.features"
          :key="i"
        >
          <div
            class="view-plan__check mr-2 primary d-flex justify-center align-center"
          >
            <img :src="require('@/assets/images/icons/check.svg')" alt="" />
          </div>
          <span class="font-16 font-600">{{ feature }}</span>
        </div>
      </div>
    </div>

    <!-- Trial notice -->
    <p v-if="!profile.hadTrial" class="free-trial font-20 font-700 mt-5">
      Dive In Risk-Free: 14 Days of Discovery
    </p>

    <!-- terms and conditions -->
    <p class="font-400 mt-5">
      By subscribing, you agree to our
      <a href="https://ofero.co/terms/" target="_blank">Terms and Conditions</a>
      and
      <a href="https://ofero.co/privecy-and-policy/" target="_blank"
        >Privacy Policy</a
      >.
    </p>

    <div class="d-flex align-center justify-end mt-10">
      <v-btn
        class="radius-15 flex-grow-1"
        height="45px"
        color="primary"
        elevation="0"
        @click="subscribe"
        :loading="loading"
        :disabled="loading"
      >
        <span class="white--text font-18 font-600">Confirm Subscription</span>
      </v-btn>

      <v-btn
        class="ml-4 no-box-shadow"
        width="80px"
        height="45px"
        color="transparent"
        @click="$emit('close')"
      >
        <span class="font-18 font-400">Cancel</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import PlanTypes from "../../../helpers/planTypesEnum.js";
import moment from "moment";

export default {
  props: {
    selectedPlan: {
      required: true,
    },
  },
  data() {
    return {
      PlanTypes,
      loading: false,
    };
  },
  computed: {
    moment,
    numberOfProducts() {
      return this.selectedPlan.package.numberOfProducts.find(
        (i) => i._id === this.selectedPlan.numberOfProductsId
      );
    },
    numberOfPreviews() {
      return this.selectedPlan.package.numberOfPreviews.find(
        (i) => i._id === this.selectedPlan.numberOfPreviewsId
      );
    },
    startDate() {
      let today = new Date().toLocaleDateString();

      return today;
    },
    endDate() {
      let endDate = this.profile.hadTrial
        ? this.selectedPlan.planType === this.PlanTypes.MONTHLY
          ? moment().add(1, "month")
          : moment().add(1, "year")
        : moment().add(14, "days");

      return new Date(endDate).toLocaleDateString();
    },
  },
  mounted() {},
  methods: {
    async subscribe() {
      if (!localStorage.getItem("token")) {
        this.$router.replace("/signin");
        return;
      }

      this.loading = true;
      const willHaveTrial = !this.profile.hadTrial;
      let planSelected;
      planSelected = {
        plan: this.selectedPlan._id,
      };

      if (!willHaveTrial) {
        planSelected.stripeId = "";

        let selectedPlanType =
          this.selectedPlan.planType === this.PlanTypes.MONTHLY
            ? "month"
            : "year";

        this.selectedPlan.pricing.plansOptions.forEach((option) => {
          console.log(option, [
            this.selectedPlan.numberOfPreviewsId,
            this.selectedPlan.numberOfProductsId,
            this.selectedPlan.price,
            selectedPlanType,
          ]);
          if (
            option.previewsId === this.selectedPlan.numberOfPreviewsId &&
            option.productsId === this.selectedPlan.numberOfProductsId &&
            option.price === this.selectedPlan.price &&
            option.type === selectedPlanType
          ) {
            planSelected.stripeId = option.stripePriceId;
          }
        });
      }

      // console.log(planSelected, process.env.VUE_APP_TRIAL_SUBSCRIPTION);

      if (willHaveTrial) {
        // start trial
        this.$axios
          .post(process.env.VUE_APP_TRIAL_SUBSCRIPTION, planSelected)
          .then((res) => {
            console.log(res, "res plan");
            this.$store.dispatch("showSnack", {
              text: "Now , you can start your trial",
              color: "success",
              timeout: 3000,
            });
            this.loading = false;
            this.$emit("update");
          })
          .catch((err) => {
            this.loading = false;
            this.$store.dispatch("showSnack", {
              text: err.message || err.error || "Something went wrong!",
              color: "error",
              timeout: 3000,
            });
          });
      } else {
        this.$axios
          .post(process.env.VUE_APP_SUBSCRIPTIONS, planSelected)
          .then((res) => {
            console.log(res, "res plan");
            this.loading = false;
            window.open(res.data.url, "_self");
          })
          .catch((err) => {
            this.loading = false;
            this.$store.dispatch("showSnack", {
              text: err.message || err.error || "Something went wrong!",
              color: "error",
              timeout: 3000,
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.free-trial {
  border-bottom: 2px solid #0195a9;
  width: fit-content;
}
</style>
