<template>
  <div class="confirm-subscription">
    <div>
      <h1 class="font-20 black--text d-block font-700">
        Update Your Subscription Plan
      </h1>
      <p class="font-18 icon-black--text mb-6 mt-2">
        {{
          this.profile.isTrial
            ? "Plan changes require card payment. Your current benefits remain until your free trial ends."
            : "Plan changes require card payment. Your current benefits remain until your billing ends."
        }}
      </p>
    </div>

    <p class="font-400 mt-5 mb-0">Proceed with the change?</p>

    <div class="d-flex align-center justify-end mt-2">
      <v-btn
        class="radius-15 flex-grow-1"
        height="45px"
        color="primary"
        elevation="0"
        @click="subscribe"
        :loading="loading"
        :disabled="loading"
      >
        <span class="white--text font-18 font-600">Confirm Subscription</span>
      </v-btn>

      <v-btn
        class="ml-4 no-box-shadow"
        width="80px"
        height="45px"
        color="transparent"
        @click="$emit('close')"
      >
        <span class="font-18 font-400">Cancel</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import PlanTypes from "../../../helpers/planTypesEnum.js";
export default {
  props: {
    selectedPlan: {
      required: true,
    },
  },
  data() {
    return {
      PlanTypes,
      loading: false,
    };
  },
  computed: {
    numberOfProducts() {
      return this.selectedPlan.package.numberOfProducts.find(
        (i) => i._id === this.selectedPlan.numberOfProductsId
      );
    },
    numberOfPreviews() {
      return this.selectedPlan.package.numberOfPreviews.find(
        (i) => i._id === this.selectedPlan.numberOfPreviewsId
      );
    },
    startDate() {
      let today = new Date().toLocaleDateString();

      return today;
    },
    endDate() {
      let today = new Date().getDate();

      let endDate = new Date().setDate(today + 14);

      return new Date(endDate).toLocaleDateString();
    },
  },
  mounted() {},
  methods: {
    async subscribe() {
      if (!localStorage.getItem("token")) {
        this.$router.replace("/signin");
        return;
      }

      this.loading = true;
      const willHaveTrial = !this.profile.hadTrial;
      let planSelected;
      planSelected = {
        plan: this.selectedPlan._id,
      };

      if (!willHaveTrial) {
        planSelected.stripeId = "";

        let selectedPlanType =
          this.selectedPlan.planType === this.PlanTypes.MONTHLY
            ? "month"
            : "year";

        this.selectedPlan.pricing.plansOptions.forEach((option) => {
          console.log(option, [
            this.selectedPlan.numberOfPreviewsId,
            this.selectedPlan.numberOfProductsId,
            this.selectedPlan.price,
            selectedPlanType,
          ]);
          if (
            option.previewsId === this.selectedPlan.numberOfPreviewsId &&
            option.productsId === this.selectedPlan.numberOfProductsId &&
            option.price === this.selectedPlan.price &&
            option.type === selectedPlanType
          ) {
            planSelected.stripeId = option.stripePriceId;
          }
        });
      }

      console.log(planSelected, process.env.VUE_APP_TRIAL_SUBSCRIPTION);

      if (willHaveTrial) {
        // start trial
        this.$axios
          .post(process.env.VUE_APP_TRIAL_SUBSCRIPTION, planSelected)
          .then((res) => {
            console.log(res, "res plan");
            this.$store.dispatch("showSnack", {
              text: "Now , you can start your trial",
              color: "success",
              timeout: 3000,
            });
            this.loading = false;
            this.$emit("update");
          })
          .catch((err) => {
            this.loading = false;
            this.$store.dispatch("showSnack", {
              text: err.message || err.error || "Something went wrong!",
              color: "error",
              timeout: 3000,
            });
          });
      } else {
        this.$axios
          .post(process.env.VUE_APP_SUBSCRIPTIONS, planSelected)
          .then((res) => {
            console.log(res, "res plan");
            this.loading = false;
            window.open(res.data.url, "_self");
          })
          .catch((err) => {
            this.loading = false;
            this.$store.dispatch("showSnack", {
              text: err.message || err.error || "Something went wrong!",
              color: "error",
              timeout: 3000,
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.free-trial {
  border-bottom: 2px solid #0195a9;
  width: fit-content;
}
</style>
